import React, { useState, useEffect } from 'react';
import './LanguageSwitcher.css'; // Asegúrate de tener este archivo CSS

const LanguageSwitcher = ({ onChangeLanguage }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Recuperar el idioma guardado desde el localStorage o establecer 'es' como valor predeterminado
  const savedLanguage = localStorage.getItem('language') || 'es';
  const [language, setLanguage] = useState(savedLanguage);

  // Definir los idiomas disponibles
  const languages = {
    es: 'ES',
    en: 'EN',
  };

  // Cambiar el idioma al que se pasó y guardar el idioma seleccionado en localStorage
  const handleLanguageChange = (lang) => {
    setLanguage(lang); // Actualizar el idioma en el estado
    localStorage.setItem('language', lang); // Guardar el idioma seleccionado en localStorage
    onChangeLanguage(lang); // Llamar la función onChangeLanguage pasada como prop
    setIsOpen(false); // Cerrar el menú después de seleccionar un idioma
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Alternar el estado del menú desplegable
  };

  useEffect(() => {
    // Actualizar el idioma cada vez que cambie el estado 'language'
    onChangeLanguage(language);
  }, [language, onChangeLanguage]);

  return (
    <div className="language-switcher">
      <button className="lang-button" onClick={toggleMenu}>
        {languages[language]} {/* Mostrar el idioma actual */}
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          {Object.keys(languages).map((lang) => (
            <button key={lang} onClick={() => handleLanguageChange(lang)}>
              {languages[lang]} {/* Mostrar todas las opciones de idioma */}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
