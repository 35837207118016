//App,js
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import LanguageSwitcher from './LanguageSwitcher.js';
import { translations } from './Translations.js';
import './App.css';
import './dashboard.css';
import GoByStart from './go_by/goByStart.js';
import Procedures from './procedures/procedures.js';
import Admin from './admin/admin.js';
import Tutos from './tutos/tutos.js';
import Guidelines from './guidelines/guidelines.js';

function App() {
  const [language, setLanguage] = useState('es');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [message, setMessage] = useState('');
  const [role, setRole] = useState('');
  const [username, setUsername] = useState('');
  const [currentBox, setCurrentBox] = useState(null);
  const [selectedBox, setSelectedBox] = useState(false);
  const [selectedState] = useState(null);
  const [selectedBusinesType] = useState(null);
  const [showRegisterForm, setShowRegisterForm] = useState(false); 
  
  const apiRoot = process.env.REACT_APP_API_URL;
 
  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    const storedRole = localStorage.getItem('role');
    
    if (storedUsername && storedRole) {
      setIsLoggedIn(true);
      setUsername(storedUsername);
      setRole(storedRole);
    }
  }, []);

  const changeLanguage = (lang) => {
    setLanguage(lang);  // Actualizar el estado con el idioma seleccionado
  };

  const handleRegister = async (username, password, email) => {
    const formData = new FormData();
    formData.append('action', 'register');
    formData.append('targetUsername', username);
    formData.append('password', password);
    formData.append('email', email); 

    try {
      const response = await axios.post(apiRoot + "login_logic.php", formData);
      const result = response.data;

      if (result.success) {
        setMessage(result.message);
        setShowRegisterForm(false);
        // No es necesario establecer rol y nombre de usuario ya que el usuario está inactivo
      } else {
        setMessage(result.message);
      }

    } catch (error) {
      if (error.response) {
        setMessage(`Error: ${error.response.data.message}`);
      } else if (error.request) {
        setMessage('Error: No hay respuesta del servidor.');
      } else {
        setMessage('Error: Ocurrió un problema al intentar registrarse.');
      }
    }
  };

  const handleLogin = async (username, password) => {
    const formData = new FormData();
    formData.append('action', 'login');
    formData.append('username', username);
    formData.append('password', password);

    try {
      const response = await axios.post(apiRoot + 'login_logic.php', formData);
      const result = response.data;
      
      if (result.success) {
        setIsLoggedIn(true);
        setMessage(result.message);
        setRole(result.role);
        setUsername(result.username);
      
        localStorage.setItem('username', result.username);
        localStorage.setItem('role', result.role);
      } else {
        setMessage(result.message);
      }

    } catch (error) {
      if (error.response) {
        setMessage(`Error: ${error.response.data.message}`);
      } else if (error.request) {
        setMessage('Error: No hay respuesta del servidor.');
      } else {
        setMessage('Error: Ocurrió un problema al intentar iniciar sesión.');
      }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('role');
    setIsLoggedIn(false);
    setUsername('');
    setRole('');
    openBox('', false);
    setMessage('Sesión cerrada');
  };

  const openBox = (name, bool) => {
    setCurrentBox(name);
    setSelectedBox(bool);
  };

  const handleGoToHome = () => {
    openBox('', false);
  };

  const handleGoToAdmin = () => {
    openBox('', false)
  };

  const handleGoToGuidelines = () => {
    openBox('', false)
  };

  const handleGoToAdministracion = () => {
    openBox('', false)
  };

  const showSelectedBox = () => {
    switch (currentBox) {
      case 'go_by':
        return (
          <>
          <GoByStart 
            apiRoot={apiRoot}
            role={role}
            username={username}
            selectedState={selectedState}
            selectedBusinesType={selectedBusinesType}
          />
          </>
        );
      case 'procedures':
        return (
          <>
          Coming soon...
          {/* 
          <Procedures 
            apiRoot={apiRoot}
            role={role}
            username={username}
          />
          */}
          </>
        );
      case 'admin':
        return (
          <>
          <Admin 
            apiRoot={apiRoot}
            role={role}
            username={username}
          />
          </>
        );
      case 'tutos':
        return (
          <>
          <Tutos 
            apiRoot={apiRoot}
            role={role}
            username={username}
          />
          </>
        );
      case 'guidelines':
        return (
          <>
          <Guidelines 
            apiRoot={apiRoot}
            role={role}
            username={username}
          />
          </>
        );
      // Agregar más aqui
      default:
        return <h1>Coming soon...</h1>;
    }
  };

  function RegisterForm({ handleRegister }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      // Validación básica del email
      if (!email.includes('@')) {
        setMessage('Por favor ingrese un correo electrónico válido.');
        return;
      }
  
      handleRegister(username, password, email);
    };
  
    return (
      <section className='login'>
        <div className="header">
          <h2>User registrer</h2>
        </div>
        <div id="section-login">
          <div id="login-main-content">
            <form onSubmit={handleSubmit} className='login-form'>
              <label htmlFor="username">{translations[language].greeting}</label>
              <input 
                type="text" 
                id="username" 
                name="username" 
                value={username} 
                onChange={(e) => setUsername(e.target.value)} 
                required 
              />
      
              <label htmlFor="email">Email:</label>
              <input 
                type="email" 
                id="email" 
                name="email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                required 
              />
      
              <label htmlFor="password">Password:</label>
              <input 
                type="password" 
                id="password" 
                name="password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                required 
              />
  
              <button type="submit">Submit</button>
              <button type="button" onClick={() => setShowRegisterForm(false)}>Cancel</button>
            </form>
            {message && <p>{message}</p>}
          </div>
        </div>
      </section>
    );
  }
  

  function Login({ onLogin, message }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
      e.preventDefault();
      onLogin(username, password);
    };

    return (
      <section className='login'>
        <div className="header">
          <h2>Login</h2>
        </div>
        <div id="section-login">
          <div id="login-main-content">
            <form onSubmit={handleSubmit} className='login-form'>
              <label htmlFor="username">Username:</label>
              <input 
                type="text" 
                id="username" 
                name="username" 
                value={username} 
                onChange={(e) => setUsername(e.target.value)} 
                required 
              />
              <label htmlFor="password">Password:</label>
              <input 
                type="password" 
                id="password" 
                name="password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                required 
              />
              <button type="submit">Submit</button>
              <button type="button" onClick={() => setShowRegisterForm(true)}>Register </button>
            </form>
            {message && <p>{message}</p>}
          </div>
        </div>
      </section>
    );
  }

  function Dashboard({ 
    role, 
    username, 
    onLogout, 
    openBox, 
    selectedBox, 
    currentBox, 
    handleGoToHome, 
    handleGoToAdmin, 
    handleGoToGuidelines, 
    handleGoToAdministracion, 
    showSelectedBox
  }) {
    const renderRoleBasedButtons = () => {
      if (currentBox !== 'go_by') return null;
  
      if (role === 'admin') {
        return (
          <>
            <button className="nav-button" onClick={() => openBox('admin', true)}>
              Admin
            </button>
          </>
        );
      } else if (role === 'super_admin') {
        return (
          <>
            <button className="nav-button" onClick={() => openBox('admin', true)}>
              Admin
            </button>
          </>
        );
      }
  
      return null; // No buttons for 'user'
    };
  
    return (
      <div className='dashboard'>    
        <div className="header">
          <h2>{translations[language].bt_start}</h2>
          <nav>
            
            {selectedBox && (
              <button className="nav-button" onClick={handleGoToHome}>
                {translations[language].bt_start}
              </button>
            )}
  
            {renderRoleBasedButtons()}
  
            <button className="nav-button" onClick={onLogout}>
              {translations[language].bt_log_out}
            </button>
            <button className="nav-button" id="nav-lang-bt" >
              <LanguageSwitcher onChangeLanguage={changeLanguage} />
            </button>
          </nav>
        </div>
        
        {!selectedBox ? (
          <Cardboxes 
            openBox={openBox} 
            apiRoot={apiRoot} 
            role={role}
          />
        ) : (
          showSelectedBox()
        )}
      </div>
    );
  }
  

  function CardBoxAdmin({openBox, role}){
    return(
      <>
        <button className="dashboard-box-admin" onClick={() => openBox('admin', true)}>
          <div>
            {/* <div className='coming-soon-ribbon'>In the works...</div> */}            
            <h1 className="dashboard-box-header">{translations[language].card_box_admin_name}</h1>
            <div className="dashboard-box-body">
              <p>{translations[language].card_box_admin_description}</p>
            </div>
          </div>
        </button>
      </>
    )
  }

  function CardBoxGoBy({openBox, role}){
    return(
      <>
        <button className="dashboard-box" onClick={() => openBox('go_by', true)}>
          <div>
            <h1 className="dashboard-box-header">{translations[language].card_box_go_by_name}</h1>
            <div className="dashboard-box-body">
              <p>{translations[language].card_box_go_by_description}</p>
            </div>
          </div>
        </button>
      </>
    )
  }

  function CardBoxProcedures({openBox, role}){    
    return(
      <>
        <button className="dashboard-box" onClick={() => openBox('procedures', true)}>
          <div>
            <div className='coming-soon-ribbon'>Coming soon...</div>
            <h1 className="dashboard-box-header">{translations[language].card_box_procedure_name}</h1>
            <div className="dashboard-box-body">
              <p>{translations[language].card_box_procedure_description}</p>
            </div>
          </div>
        </button>
      </>
    )
  }

  function CardBoxTutos({openBox, role}){    
    return(
      <>
        <button className="dashboard-box" onClick={() => openBox('tutos', true)}>
          <div>
            <div className='coming-soon-ribbon'>Coming soon...</div>
            <h1 className="dashboard-box-header">{translations[language].card_box_tutorials_name}</h1>
            <div className="dashboard-box-body">
              <p>{translations[language].card_box_tutorials_description}</p>
            </div>
          </div>
        </button>
      </>
    )
  }

  function CardBoxGuidelines({openBox, role}){    
    return(
      <>
        <button className="dashboard-box" onClick={() => openBox('guidelines', true)}>
          <div>
            <h1 className="dashboard-box-header">{translations[language].card_box_guidelines_name}</h1>
            <div className="dashboard-box-body">
              <p>{translations[language].card_box_guidelines_description}</p>
            </div>
          </div>
        </button>
      </>
    )
  }

  function Cardboxes ({ openBox, apiRoot, role }) {
    switch (role) {
      case 'admin':
        return (
          <section> 
              <div className="dashboard-container">
                <CardBoxAdmin            
                  openBox={openBox}                  
                  role={role}
                />  
                <CardBoxGoBy            
                  openBox={openBox}                  
                  role={role}
                /> 
                <CardBoxProcedures            
                  openBox={openBox}                  
                  role={role}
                /> 
                <CardBoxTutos            
                  openBox={openBox}                  
                  role={role}
                />
                <CardBoxGuidelines            
                  openBox={openBox}                  
                      role={role}
                />
              </div>
          </section>
        );
      case 'user':
        return (
          <section>
            <div className="dashboard-container"> 
              <CardBoxGoBy            
                openBox={openBox}                  
                    role={role}
              /> 
              <CardBoxProcedures            
                openBox={openBox}                  
                    role={role}
              /> 
              <CardBoxTutos            
                openBox={openBox}                  
                    role={role}
              />
              <CardBoxGuidelines            
                openBox={openBox}                  
                    role={role}
              />
              </div>
          </section>
        );
      // Agregar más aqui
      default:
        return <h1>Coming soon...</h1>;
    }
  }
  

  

  return (
    <div>
      
      
      {!isLoggedIn ? (
         // Si no está logueado
         <>
         {showRegisterForm ? (
           // Si el usuario eligió registrarse, mostrar el formulario de registro
           <RegisterForm
             handleRegister={handleRegister}
             message={message}
             onCancel={() => setShowRegisterForm(false)} // Cancelar registro y volver al login
           />
         ) : (
           // Si no, mostrar el formulario de login
           <Login
             onLogin={handleLogin}
             message={message}
             onShowRegister={() => setShowRegisterForm(true)} // Mostrar el formulario de registro
           />
         )}
       </>
      ) : (       
        <Dashboard           
          apiRoot={apiRoot}
          username={username} 
          role={role} 
          onLogout={handleLogout} 
          openBox={openBox} 
          selectedBox={selectedBox}
          currentBox={currentBox}
          handleGoToHome={handleGoToHome}
          handleGoToAdmin={handleGoToAdmin}
          handleGoToGuidelines={handleGoToGuidelines}
          handleGoToAdministracion={handleGoToAdministracion}
          showSelectedBox={showSelectedBox}
        />
      )}
      </div>
  );
}

export default App;
