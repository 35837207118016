import { useState, useEffect } from 'react';
import axios from 'axios';
import './goByStart.css';

function GoByStart({ role, username, apiRoot}) {
  const [businessDetails, setBusinessDetails] = useState(null);
  const [states, setStates] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [toast, setToast] = useState({ message: '', success: false });


  function showToast(message, success) {
    setToast({ message, success });

    // Hide the toast after 2 seconds
    setTimeout(() => {
        setToast({ message: '', success: false });
    }, 2000); // Display for 2 seconds
  }

  // Cargar estados 
  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await axios.get(apiRoot +'go_by_logic.php?action=get_states');
        setStates(response.data.message);
      } catch (error) {
        showToast(('Error fetching states: '+ error), false);
      }
    };
    fetchStates();
  }, [apiRoot]);

  // Cargar tipos de negocios 
  useEffect(() => {
    const fetchBusinessTypes = async () => {
      try {
        const response = await axios.get(apiRoot +'go_by_logic.php?action=get_business_types');
        setBusinessTypes(response.data.message);
      } catch (error) {
        showToast(('Error fetching business types: '+ error), false);
      }
    };
    fetchBusinessTypes();
  }, [apiRoot]);

  const updateCompanyDetailsAfterSelection = async (response, stateId, selectedBusiness, selectedCompany, selectedColumn) => {    
    let flag = false; // Inicializar el flag para comprobar si se encuentra la compañía
    // Si hay una compañía seleccionada y una columna seleccionada
    if (selectedCompany && selectedColumn) {
      // Encuentra la columna especificada en el response
      const selectedColumnData = response.data.message.find(column => column.column === selectedColumn);
      
      // Si se encontró la columna, buscar la compañía dentro de esa columna
      if (selectedColumnData) {
        selectedColumnData.companies.forEach(company => {
          if (company.id === selectedCompany) {
            flag = true; // Marca flag como verdadero si la compañía seleccionada está en la columna
          }
        });
      }

      if (flag) {
        // Si se encuentra la compañía, obtenemos sus detalles
        //console.log('Compañía encontrada, obteniendo detalles');
        getCompanyDetails(stateId, selectedBusiness, selectedCompany, selectedColumn);
      } else {
        // Si no se encuentra, limpia los detalles de la compañía
        
        //console.log('Compañía no encontrada, limpiando detalles');
        setCompanyDetails({ html: "<p></p>" });
      }
    }
  }

  const handleStateClick = async (stateId) => {
    setSelectedState(stateId);
    try {
      // Realiza la llamada API para obtener las compañías
      const response = await axios.get(`${apiRoot}go_by_logic.php?action=get_companies&business_type_id=${selectedBusiness}&state_id=${stateId}`);
      
      // Establece los detalles del negocio
      setBusinessDetails(response.data.message);
      
      updateCompanyDetailsAfterSelection(response, stateId, selectedBusiness, selectedCompany, selectedColumn)


    } catch (error) {
      showToast(('Error fetching company details: '+ error), false);
    }
  }; 

  const handleBusinessClick = async (businessType) => {
    setSelectedBusiness(businessType);

    try {
      const response = await axios.get(apiRoot + `go_by_logic.php?action=get_companies&business_type_id=${businessType}&state_id=${selectedState}`);
      setBusinessDetails(response.data.message);

      updateCompanyDetailsAfterSelection(response, selectedState, businessType, selectedCompany, selectedColumn)

    } catch (error) {
      showToast(('Error fetching company details: '+ error), false);
    }
  }; 

  const getCompanyDetails = async (selectedState, selectedBusiness, company, columnName) => {
    document.addEventListener('DOMContentLoaded', function() {
      
      // Limpiar el contenido del área de detalles de la compañía
      document.getElementById('company-details-section-content').innerHTML = "<p></p>";
    });

    try {
      // Llamada a la API usando axios
      const response = await axios.get(`${apiRoot}go_by_logic.php`, {
        params: {
          action: 'get_general_insurer_info',
          company_id: company,
          state_id: selectedState,
          business_type_id: selectedBusiness,
          column_name_id: columnName
        }
      });
  
      // Actualizar el estado con los detalles de la compañía si la respuesta es exitosa
      if (response.data && !response.data.error) {
        setCompanyDetails(response.data);
      } else {
        showToast('Error fetching company details: ' + response.data.message, false);
      }
      return response;
    } catch (error) {
      if (error.response && error.response.status === 429) {
        // Si recibimos un error 429, notificar al usuario y esperar
        showToast('Too many requests. Please try again later.', false);
        // Aquí podrías implementar un retraso antes de volver a intentar la solicitud si lo deseas.
        await new Promise(resolve => setTimeout(resolve, 3000)); // Esperar 3 segundos
        return null; // O realizar otra acción, como volver a intentar la solicitud
      } else {
        // Manejar otros errores
        showToast('Error fetching company details: ' + error.message, false);
      }
    }
  };
  

  const handleCompanyClick = async (selectedState, selectedBusiness, columnName, company) => {

    //setCompanyDetails('');
    setSelectedColumn(columnName); // Establece la columna activa
    setSelectedCompany(company);  // Establece la compañía seleccionada

  
    // Validación básica de parámetros
    if (!selectedState || !selectedBusiness || !company || !columnName) {
      showToast('Missing required parameters for the request.', false);
      return;
    }
  
    try {

      getCompanyDetails(selectedState, selectedBusiness, company, columnName)

    } catch (error) {
      // Manejo de error en la solicitud
      showToast(('Error fetching company details: ' + error), false);
    }
  };

  function BusinessTypeSelector ({ 
    apiRoot,
    role,
    username,
    businessTypes}
    ){
    return(
        <aside className='left_aside'>
            <h2>Business Types</h2>
            <ul>
            {businessTypes.map((businessType) => (
                <li key={businessType.id}
                onClick={() => handleBusinessClick(businessType.id)}                  
                className={`business-type ${businessType.id === selectedBusiness ? 'active' : ''}`}>
                    {businessType.name}
                </li>
            ))}
            </ul>
        </aside>
        )
    }     

  function StateSelector({ 
    apiRoot,
    role,
    username,
    states}
    ){
    return(
        <div className="state-banner">
            {states.map((state) => (
            <button
                key={state.id}
                href="#"
                onClick={() => handleStateClick(state.id)}
                className={`state-buttons ${state.id === selectedState ? 'active' : ''}`}
            >
                {state.state_name}
            </button>
            ))}
        </div>
    )
  }
  
  function CompanyColumns() { // Estado para la columna activa
  
    if (!businessDetails || businessDetails.length === 0) {
      return <p>Select a State and Business type</p>;
    }  

    // Mapeo para convertir los números en los nombres descriptivos de las columnas
    const columnNamesMap = {
      '1': 'NEW VENTURE',
      '2': '1 YEAR',
      '3': '2 YEARS',
      '4': '3+ YEARS'
    };

    return (
      <div className="company-columns">
        {['1', '2', '3', '4'].map((columnName) => {
          const columnData = businessDetails.find(col => col.column === columnName);
  
          return (
            <div key={columnName} className="company-column">
              <h3>{columnNamesMap[columnName]}</h3>
              <ul>
                {columnData?.companies.map((company) => {
                  const isSelected = company.id === selectedCompany && columnName === selectedColumn;
  
                  return (
                    <li 
                      key={company.id} 
                      className={`company-buttons ${isSelected ? 'active' : ''}`}
                      onClick={() => {
                        handleCompanyClick(selectedState, selectedBusiness, columnName, company.id, ); //<input className="company-checkbox" type="checkbox" />
                      }}>
                      
                      <label className='company-name'>{company.name}</label>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    );
  }
  
  function CompanyDetails() {
    return (
      <>
        <section id="company-details-section">
          {selectedCompany ? (
            <div > 
              {companyDetails.html ? (
                <div id="company-details-section-content" 
                  dangerouslySetInnerHTML={{ __html: companyDetails.html }}
                />
              ) : (
                <p>Nothing to show</p>
              )}
            </div>
          ) : (
            <p>No details to show </p>
          )}
        </section>
      </>
    );
  }

  return (
    <div>
        <StateSelector
            apiRoot={apiRoot}
            role={role}
            username={username}
            states={states}

        />
        <div id="main-content">
            <BusinessTypeSelector 
                apiRoot={apiRoot}
                role={role}
                username={username}
                businessTypes={businessTypes}
            />
            <div id="business-info">
              {(selectedBusiness && selectedState) ? (
                <>
                  <CompanyColumns />
                </>
              ) : (
                  <h2>Select one State and Business type</h2>
              )}
            </div>

            <aside className="right_aside">
              {(selectedCompany && companyDetails) ? (
                <CompanyDetails />
                ) : (
                  <h2>Select an Insurer</h2>
              )}
            </aside>
            
      </div>
      {toast.message && (
            <div className={`toast ${toast.success ? 'success' : 'error'}`}>
            {toast.message}
            </div>
      )}
    </div>
  );
}

export default GoByStart;
