export const translations = {
    es: {
      greeting: "Hola, mundo!",
      bt_start: "Inicio",
      bt_log_out: "Salir",

      lb_User: "Usuario:",

      card_box_admin_name: "ADMIN",
      card_box_admin_description: "Administracion de contenido",
      
      card_box_go_by_name: "Go By",
      card_box_go_by_description: "Guía para definier orden de aseguradoras según características del cliente",
      
      card_box_procedure_name: "Procesos",
      card_box_procedure_description: "Procedimeintos para asegruar una correcta ejecución de cada proceso",

      card_box_tutorials_name: "Tutoriales",
      card_box_tutorials_description: "Guías útiles para entrenamiento",

      card_box_guidelines_name: "Guidelines",
      card_box_guidelines_description: "Guidelines de aseguradoras",
    },
    en: {
      greeting: "Hello, world!",
      bt_start: "Start",
      bt_log_out: "Log Out",

      lb_User: "User:",

      card_box_admin_name: "ADMIN",
      card_box_admin_description: "Admin content",
      
      card_box_go_by_name: "Go By",
      card_box_go_by_description: "Guide to Defining the Order of Insurance Providers Based on Client Characteristics",
      
      card_box_procedure_name: "Procedures",
      card_box_procedure_description: "Procedures to Ensure the Proper Execution of Each Process",

      card_box_tutorials_name: "Tutorials",
      card_box_tutorials_description: "Useful Guides for Training",

      card_box_guidelines_name: "Guidelines",
      card_box_guidelines_description: "Insurers Guidelines",
    },
  };


//<p>{translations[language].greeting}</p>  
    